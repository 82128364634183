@import 'abstracts/variables';
@import 'abstracts/helpers';

.product-card-simple-wrapper-link {
  text-decoration: none;
  color: @text;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    color: @text;
  }

  .product-card-simple {
    flex-direction: column;
    height: 100%;
    background: @white;
    padding: @spacer * 2;
    border-radius: @border-radius;
    border: 1px solid @B200;
    transition: transform @transition-delay ease-out, box-shadow @transition-delay ease-out, opacity @transition-delay ease-out;

    // Default fixed width for carousels
    min-width: 160px;
    width: 160px;

    // Remove fixed width when inside a grid layout
    &.is-grid-item {
      width: auto;
      min-width: auto;
    }

    &.hide-border {
      border: none;
    }

    &:hover {
      transform: translate3d(0, -4px, 0);

      .product-card-buttons {
        opacity: 1 !important;
      }
    }

    p {
      margin: 0;
      font-size: @font-size-base - 1;
    }

    .image-container {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-bottom: @spacer;

      img {
        object-fit: contain;
      }

      .product-card-buttons {
        position: absolute;
        opacity: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &.align-left {
      .image-container {
        justify-content: flex-start;
      }
    }

    .product-name {
      .multiline-ellipsis();

      font-family: @headings-font-family;
      font-weight: 600;
      font-size: @font-size-base;
      margin-bottom: (@spacer / 2);
    }
  }
}
